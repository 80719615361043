import * as React from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Icons from "../Icons";
import FileDetail from "./fileDetails";
import TextTooltip from "../Tooltips/TextTooltip";
import StatusDropdown from "../Dropdown/StatusDropdown";
import ToastMessage from "../ToastMessage";
import TextTooltipDynamic from "../Tooltips/TextTooltipDynamic";

type RowData = {
  id: number;
  File_name: string;
  Publish_status: string;
  Publish_timestamp: string;
  upload_timestamp: string;
  file_status: string;
  bug_count: number;
  file_description: string;
};

export default function TableComponent({
  rows,
  setPaginationModel,
  paginationModel,
  isLoading,
  rowCountState,
  currentPage,
  onSelectionChange,
}: {
  rows: RowData[];
  setPaginationModel: any,
  paginationModel: any,
  rowCountState: any,
  isLoading?: any,
  currentPage: number;
  onSelectionChange: (selection: string | unknown[]) => void;
}) {
  console.log(currentPage);
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [drawer, setDrawer] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState<number>();
  const [isEditing, setIsEditing] = React.useState(false);

  const handleFileNameClick = (rowData: RowData) => {
    setSelectedRowData(rowData.id);
    setDrawer(true);
  };

  const handleFileDescription = (rowData: RowData) => {
    setSelectedRowData(rowData.id);
    setDrawer(true);
    setIsEditing(true);
  };

  const handleBug = (val: string) => {
    console.log(val);
  };

  const columns: GridColDef[] = [
    {
      field: "File_name",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">File name</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 565,
      editable: true,
      sortable: true,
      renderCell: (params) => {
        const isMp4 = params.row.File_name.endsWith(".mp4");
        return (
          <Box
            display={"flex"}
            paddingRight={"20px"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box
              display={"flex"}
              gap={"8px"}
              flexDirection={"row"}
              alignItems={"center"}
              sx={{
                opacity: params.row.description == "" ? 0.5 : 1,
                cursor: "pointer",
              }}
              onClick={() => handleFileNameClick(params.row)}
            >
              {isMp4 ? (
                <Icons name="recording" fill="#8E8D8A" width={18} height={18} />
              ) : (
                <Icons name="textFile" fill="#8E8D8A" width={18} height={18} />
              )}
              <Typography variant="detail" sx={{ whiteSpace: 'break-spaces' }}>{params.row.File_name}</Typography>
            </Box>
            <Box
              display={"flex"}
              gap={"8px"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              {params.row.bug_count > 0 && (
                <TextTooltip
                  title={`${params.row.bug_count} Bug${params.row.bug_count > 1 ? "s" : ""
                    } reported`}
                >
                  <Box
                    sx={{
                      paddingX: "5px",
                      paddingY: "4px",
                      borderRadius: "4px",
                      bgcolor: "#FFEEF2",
                      lineHeight: 0,
                      cursor: "pointer",
                    }}
                    component={"div"}
                  >
                    <Icons name="bug" fill="#C61341" width={16} height={16} />
                  </Box>
                </TextTooltip>
              )}
              {params.row.to_be_updated !== null && (
                <TextTooltipDynamic
                  eventId={params.row.event_status}
                >
                  <Box
                    sx={{
                      paddingX: "5px",
                      paddingY: "4px",
                      borderRadius: "4px",
                      bgcolor: "#FFEEF2",
                      lineHeight: 0,
                      cursor: "pointer",
                    }}
                    component={"div"}
                  >
                    <Icons name="warningRed" fill="#C61341" width={16} height={16} />
                  </Box>
                </TextTooltipDynamic>
              )}
              {params.row.file_description !== null &&
                params.row.file_description !== "" ? (
                <TextTooltip
                  title={
                    <Box
                      p={"12px"}
                      display={"flex"}
                      flexDirection={"row"}
                      gap={"8px"}
                      alignItems={"stretch"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"8px"}
                      >
                        <Typography component={"p"} variant="detailBold">
                          File description
                        </Typography>
                        <Typography
                          whiteSpace={"normal"}
                          component={"div"}
                          variant="detail"
                        >
                          {params.row.file_description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ cursor: "pointer" }}
                        component={"div"}
                        onClick={() =>
                          params.row.file_description !== null &&
                            params.row.file_description !== ""
                            ? handleFileDescription(params.row)
                            : null
                        }
                        display={"flex"}
                        alignItems={"flex-end"}
                      >
                        <Icons
                          name="edit"
                          fill="#FFA737"
                          width={20}
                          height={20}
                        />
                      </Box>
                    </Box>
                  }
                  html
                >
                  <Box
                    sx={{
                      paddingX: "8px",
                      paddingY: "4px",
                      borderRadius: "4px",
                      bgcolor: "#F4F6FF",
                      cursor:
                        params.row.file_description !== null &&
                          params.row.file_description !== ""
                          ? "pointer"
                          : "default",
                      lineHeight: 0,
                    }}
                  >
                    <Box
                      sx={{
                        opacity:
                          params.row.file_description !== null &&
                            params.row.file_description !== ""
                            ? 1
                            : 0.3,
                        cursor:
                          params.row.file_description !== null &&
                            params.row.file_description !== ""
                            ? "pointer"
                            : "default",
                        lineHeight: 0,
                      }}
                    >
                      <Icons
                        name="description"
                        fill="#3B4478"
                        width={16}
                        height={16}
                      />
                    </Box>
                  </Box>
                </TextTooltip>
              ) : (
                <Box
                  sx={{
                    paddingX: "8px",
                    paddingY: "4px",
                    borderRadius: "4px",
                    bgcolor: "#F4F6FF",
                    cursor:
                      params.row.file_description !== null &&
                        params.row.file_description !== ""
                        ? "pointer"
                        : "default",
                    lineHeight: 0,
                  }}
                >
                  <Box
                    sx={{
                      opacity:
                        params.row.file_description !== null &&
                          params.row.file_description !== ""
                          ? 1
                          : 0.3,
                      cursor:
                        params.row.file_description !== null &&
                          params.row.file_description !== ""
                          ? "pointer"
                          : "default",
                      lineHeight: 0,
                    }}
                  >
                    <Icons
                      name="description"
                      fill="#3B4478"
                      width={16}
                      height={16}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "Publish_status",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">File status</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 270,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        const date = new Date(params.row.Publish_timestamp);
        return params.row.Publish_status != "" ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            flexGrow={1}
            padding={"0px"}
          >
            <StatusDropdown
              sx={{ width: "100%", cursor: params.row.to_be_updated !== null ? "not-allowed" : "pointer", pointerEvents: params.row.to_be_updated !== null ? 'none' : 'auto', }}
              status={params.row.Publish_status?.toLowerCase()}
              fileId={params.row.id}
              type={"published"}
              disabled={params.row.to_be_updated !== null}
              setToast={setToast}
              timestamp={date.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
              onClickCallback={handleBug}
            />
          </Box>
        ) : (
          <Icons name="minus" fill="#8E8D8A" width={16} height={16} />
        );
      },
    },
    {
      field: "file_status",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Status</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      type: "string",
      width: 200,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return params.row.file_status != null ? (
          <Box display={"flex"} alignItems={"center"} flexGrow={1}>
            <StatusDropdown
              sx={{ width: "100%" }}
              status={params.row.file_status}
              type={"status"}
              fileId={params.row.id}
              setToast={setToast}
              timestamp={""}
              onClickCallback={handleBug}
            />
          </Box>
        ) : (
          <Icons name="minus" fill="#8E8D8A" width={16} height={16} />
        );
      },
    },
    {
      field: "Publish_timestamp",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Upload date</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const date = new Date(params.row.upload_timestamp);
        return (
          <Typography color={"#696969"} fontSize={"12px"} fontWeight={"400"}>
            {date.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ maxHeight: 600, width: "100%", position: "relative" }}>
        {rows && rows.length > 0 ? (
          <DataGrid
            rows={rows}
            rowCount={rowCountState}
            loading={isLoading}
            paginationMode="server"
            columns={columns}
            rowHeight={44}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: "published",
                    sort: "asc",
                  },
                ],
              },
            }}
            sx={{
              ".MuiDataGrid-main": {
                height: rows.length === 0 ? "235px" : "auto",
                overflow: "inherit !important"
              },
              "& .MuiDataGrid-cell": {
                transition: "background-color 0.3s",

                "&:hover": {
                  backgroundColor: "#FFF8EC",
                },
                ".css-kbr7x2": {
                  padding: "0px !important",
                },
              },
              border: "none",
              "& .MuiDataGrid-menuIcon": {
                display: "none",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "unset",
                color: "#1A1C1F",
                fontSize: 12,
                fontWeight: "600",
              },
              ".MuiDataGrid-columnHeaders": {
                bgcolor: "#F4F6FF",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                minHeight: "44px !important",
                border: "none",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },

              "& .MuiDataGrid-withBorderColor:focus": {
                outline: "none",
              },
              "& .MuiCheckbox-root": {
                borderRadius: "8px",
                fontSize: "1em",
                borderColor: "#D2D2D0",
              },
              ".MuiDataGrid-row.Mui-selected": {
                background: "#FFF8EC",
              },
              ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root:hover": {
                background: "none",
              },
              ".MuiDataGrid-row:hover": {
                background: "transparent",
              },
              ".MuiDataGrid-row.Mui-selected:hover": {
                background: "#FFF8EC",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked": {
                "& .MuiSvgIcon-root": {
                  color: "#FFA737",
                },
              },
              "& .MuiDataGrid-footerContainer": {
                justifyContent: "end",
              },
              "& .MuiDataGrid-withBorderColor:focus-within": {
                outline: "none",
              },
              ".css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover": {
                backgroundColor: "unset !important",
                color: "unset !important",
              },
              ".MuiDataGrid-iconButtonContainer": {
                display: "none",
              },
              ".MuiDataGrid-virtualScroller": {
                position: "initial !important"
              },
            }}
            onRowSelectionModelChange={onSelectionChange}
            pageSizeOptions={[15, 25, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        ) : (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            No data found
          </Typography>
        )}
      </Box>
      <FileDetail
        drawer={drawer}
        setDrawer={setDrawer}
        selectedRowData={selectedRowData}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            position: "abosolute",
            top: "90px",
            textTransform: "capitalize",
          }}
        />
      )}

    </>
  );
}


