import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { getEventDetails } from "../../services/api/fileLibrary";
import Cookies from "js-cookie";
import { Button, Typography } from "@mui/material";

interface tipProps {
  eventId: React.ReactNode | string;
  children: React.ReactElement;
}

const TextTooltipDynamic = ({ eventId, children }: tipProps) => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("")
  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = (eventId: any) => {
  //   const verifyAccessString = Cookies.get("authAccess");
  //   const verifyAccess = verifyAccessString
  //     ? JSON.parse(verifyAccessString)
  //     : null;
  //   const token = verifyAccess.token;
  //   getEventDetails(token, eventId).then((res) => {
  //     console.log(res, "events details", res.data)
  //     setOpen((prevOpen) => !prevOpen);
  //     const data: any = {}
  //     res.data.data.map((file: any) => {
  //       data[file.event_name] = file.event_status
  //     })
  //     setTitle(JSON.stringify(data));
  //   })
  // };
  const handleTooltipOpen = (eventId: any) => {
    const verifyAccessString = Cookies.get("authAccess");
    const verifyAccess = verifyAccessString
      ? JSON.parse(verifyAccessString)
      : null;
    const token = verifyAccess.token;
    getEventDetails(token, eventId).then((res) => {
      const data = res.data.data.reduce((acc:any, file:any) => {
        acc[file.event_name] = file.event_status;
        return acc;
      }, {});
      setTitle(data);
      setOpen(true);
    });
  };

  const handleRetry = (event:any) => {
    console.log("event",event)
    // Add retry logic here for the failed event
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        arrow
        open={open}
        onClose={() => setOpen(false)}
        title={
          <Box sx={{ padding: 1 }}>
            {title &&
              Object.entries(title).map(([name, status]) => (
                <Box key={name} display="flex" alignItems="center" gap={1} sx={{ marginBottom: '4px' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: status === 'completed' ? '#4CAF50' : '#F44336',
                      fontWeight: 500,
                    }}
                  >
                    {name}:
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 400 }}>
                    {status}
                  </Typography>
                  {status === 'failed' && (
                    <Button
                      size="small"
                      onClick={(e) => handleRetry(e)}
                      sx={{
                        backgroundColor: '#FF9800',
                        color: '#FFF',
                        minWidth: 0,
                        padding: '2px 6px',
                        fontSize: '10px',
                        '&:hover': {
                          backgroundColor: '#F57C00',
                        },
                      }}
                    >
                      Retry
                    </Button>
                  )}
                </Box>
              ))}
          </Box>
        }
        PopperProps={{
          disablePortal: true,
        }}
        placement="left"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#3B4478",
              color: "#FFF",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "15px",
              borderRadius: "4px",
              maxWidth: "unset !important",
            },
          },
          arrow: {
            sx: {
              "&:before": {
                bgcolor: "#3B4478",
              },
            },
          },
        }}
      >
        <Box
          onClick={()=>handleTooltipOpen(eventId)}
          component="div"
          sx={{ cursor: "pointer" }}
        >
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TextTooltipDynamic;
